<template>
    <AppLayout>
        <v-row justify="center" class="py-5">
            <h1 class="text-h6 font-weight-light">Reports</h1>
        </v-row>
    </AppLayout>
</template>
<script>
import AppLayout from '@/components/AppLayout.vue';

export default {
    components: {
        AppLayout,
    },
};
</script>
